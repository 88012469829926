import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { ConfigProvider } from "antd"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import localeData from "dayjs/plugin/localeData"
import weekday from "dayjs/plugin/weekday"
import weekOfYear from "dayjs/plugin/weekOfYear"
import weekYear from "dayjs/plugin/weekYear"
import { createContext, memo, useContext, type FC, type PropsWithChildren } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { version } from "../../../package.json"
import { GeneralContextProvider } from "../../context/GeneralContext"
import introspection from "../../graphql"
import routes from "../../pages"
import theme from "../../themes"
import "./index.less"
import { ResponsiveContextProvider } from "../../context/ResponsiveContext"

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

type ContextProps = {
  app: { version: string }
}

const app: ContextProps["app"] = { version }

const Context = createContext({ app })

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>
}

const useApp: () => ContextProps = () => useContext(Context)

const httpLink = createHttpLink({
  uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
  credentials: "same-origin",
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("jwt")
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContextProvider app={app}>
      <ResponsiveContextProvider>
        <GeneralContextProvider>
          <ConfigProvider theme={theme}>
            <RouterProvider router={router} />
          </ConfigProvider>
        </GeneralContextProvider>
      </ResponsiveContextProvider>
    </ContextProvider>
  </ApolloProvider>
))

export { useApp }

export default App
