import { useGeneralContext } from "../context/GeneralContext"
import { useNavigate } from "react-router"

const useLogout = () => {

  const { setShop, setProfile, setAccount, setShopOptions } = useGeneralContext()
  const navigate = useNavigate()

  const logout = () => {
    setProfile(undefined)
    setShop("")
    setAccount("")
    setShopOptions([])
    localStorage.removeItem("jwt")
    navigate("/sign-in")
  }

  return logout;
};

export default useLogout;
